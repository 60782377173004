import CampaignsContactListCell from './campaigns_contact_list_cell.svelte';

/**
 * Displays the contact list name and the number of contacts in the list
 */
function campaignsContactListCell(cell) {
  const div = document.createElement('div');

  new CampaignsContactListCell({
    target: div,
    props: {
      contactListName: cell.getData().contact_list_name ?? '',
      contactListSize: cell.getData().contact_list_size ?? '',
    },
  });

  return div;
}

export default { campaignsContactListCell };
