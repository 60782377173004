import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['sidebar', 'sidebarLink'];

  static values = { openClasses: Array };

  toggleSidebar() {
    this.sidebarTarget.classList.toggle('hidden');
    this.sidebarLinkTarget.classList.toggle('hidden');
    this.sidebarTarget.classList.toggle('translate-x-full');
    this.toggleOpenClasses();
    this.sidebarTarget.classList.toggle('translate-x-0');
    this.updateSidebarCookieState(this.sidebarTarget.classList);
  }

  updateSidebarCookieState(classList) {
    const sidebarState = classList.contains('hidden') ? 'hidden' : 'visible';
    document.cookie = `campaign_editor_sidebar_state=${sidebarState}; SameSite=Lax; path=/;`;
  }

  toggleOpenClasses() {
    this.openClassesValue.forEach((openClass) => {
      this.element.classList.toggle(openClass);
    });
  }
}
