import { Controller } from '@hotwired/stimulus';
import { TabulatorFull as Tabulator } from 'tabulator-tables';
import { DateTime } from 'luxon';
import formatters from './formatters/formatters';
import CustomPaginationModule from './pagination/pagination_module';
import CustomSearchModule from './search/search_module';
import { ajaxSettings, staticSettings } from './config/base_settings';
import headerUISortCustomization from './header/header';

// Connects to data-controller="tabulator-table"
export default class extends Controller {
  static values = {
    columns: Array,
    enableSearch: Boolean,
    searchPlaceholder: String,
    rowHeight: Number,
    data: Array,
    endpoint: String,
    lastPage: Number,
  };

  connect() {
    this.registerTabulatorModules();
    this.initializeTabulator(this.columns());
  }

  columns() {
    return this.columnsValue.map((column) => {
      const { formatter } = column;
      if (formatter && formatters[formatter]) {
        return { ...column, formatter: formatters[formatter] };
      }
      return column;
    });
  }

  initializeTabulator(columns) {
    let settings = this.endpointValue ? ajaxSettings(this.endpointValue) : staticSettings(this.dataValue);

    // NOTE(jkogara): Allows us to preload the data at the same time as providing an endpoint to fetch more data.
    if (this.endpointValue && this.dataValue && this.dataValue.length > 0) {
      settings = { ...settings, data: this.dataValue };
    }

    this.tabulator = new Tabulator(this.element, {
      // Layout settings
      rowHeight: this.rowHeightValue ?? 'auto',
      layout: 'fitColumns', // fitColumns, fitData, fitDataFill, fitDataStretch
      minHeight: 200,

      // Custom search options
      customSearchOptions: {
        placeholder: this.searchPlaceholderValue,
      },

      // Table dependencies
      dependencies: {
        DateTime, // this is for the luxon library datetime methods
      },

      // Columns
      columns,
      autoColumns: columns.length === 0,

      dataLoaderError: 'There was an error with this request. Please try again later.', // Custom error element
      dataLoaderErrorTimeout: 7000,

      // Header sort element customization
      headerSortElement: headerUISortCustomization,

      // Ajax settings if an endpoint is provided
      ...settings,
    });

    // NOTE(jkogara): Allows us to preload the data at the same time as providing an endpoint to fetch more data.
    if (this.dataValue && this.dataValue.length > 0 && this.lastPageValue) {
      this.tabulator.setMaxPage(this.lastPageValue);
    }
  }

  registerTabulatorModules() {
    // Custom modules for pagination and search (only relevant when using ajax)
    if (this.endpointValue) {
      Tabulator.registerModule(CustomPaginationModule);
      if (this.enableSearchValue) {
        Tabulator.registerModule(CustomSearchModule);
      }
    }
  }
}
