import debounce from 'debounce';
import BaseController from '../../javascript/controllers/base_controller';

export default class extends BaseController {
  connect() {
    this.submit = debounce(this.submit.bind(this), 300);
  }

  submit() {
    this.element.requestSubmit();
  }
}
