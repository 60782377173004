<script>
  export let identifier;
  export let filters;

  function filterLabel(filter){
    if (filter.args.length > 0) {
      const args = filter.args.map((arg) => `"${arg}"`).join(', ');
      return [filter.name, args].join(': ');
    }
    return filter.name;
  }

  function hasFiltersOtherThanDefault(){
    return filters.length > 1 || filters.length === 1 && filters[0].name !== 'default';
  }
</script>

{#if hasFiltersOtherThanDefault()}
  <span class="text text-xs text-gray-400">
    {"{{"}
  </span>

  <span class="text text-xs text-gray-400">
    {identifier}
  </span>

  {#each filters as filter}
    <span class="text text-xs text-gray-400">
      { " | "}
    </span>
    {#if filter.name === 'default'}
        <span class="text text-xs text-gray-400">
          {filterLabel(filter)}
        </span>
      {:else}
        <span class="text text-xs badge badge-ghost">
          {filterLabel(filter)} 
        </span>
      {/if}
  {/each}


  <span class="text text-xs text-gray-400">
    {"}}"} 
  </span>

{:else}
  <span class="text text-xs text-gray-400">
    No transformations applied
  </span>
{/if}
