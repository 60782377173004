import { logger } from '@root/utilities/logger';
import BaseController from '@root/controllers/base_controller';

export default class extends BaseController {
  static targets = ['sidebarContainer', 'icon', 'link', 'expanded'];

  toggle() {
    if (this.sidebarContainerTarget.dataset.expanded === '1') {
      this.collapse();
    } else {
      this.expand();
    }
    // Sets a cookie to remember the state of the sidebar
    document.cookie = `sidebar_expanded=${this.sidebarContainerTarget.dataset.expanded}; path=/`;
    logger.debug(`Cookie value ${document.cookie}`);
  }

  collapse() {
    this.sidebarContainerTarget.classList.add('sm:w-1/8');
    this.linkTargets.forEach((link) => {
      link.classList.add('sr-only');
    });
    this.sidebarContainerTarget.dataset.expanded = '0';
  }

  expand() {
    this.sidebarContainerTarget.classList.remove('sm:w-1/8');
    this.linkTargets.forEach((link) => {
      link.classList.remove('sr-only');
    });
    this.sidebarContainerTarget.dataset.expanded = '1';
  }
}
