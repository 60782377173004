import { DateTime } from 'luxon';

/**
 * Custom formatter for verbal estimated datetime
 */
export default function relativeDateTimeCellFormatter(cell) {
  const div = document.createElement('div');
  const dateValue = cell.getValue();

  if (!dateValue) {
    div.innerHTML = 'No date provided';
    return div;
  }

  // Attempt to parse as ISO
  let dateTime = DateTime.fromISO(dateValue, { zone: 'local' });
  if (!dateTime.isValid) {
    // Attempt parsing with a custom format if ISO fails
    dateTime = DateTime.fromFormat(dateValue, 'yyyy-MM-dd HH:mm:ss ZZZ', { zone: 'local' });
  }

  if (!dateTime.isValid) {
    div.innerHTML = 'Invalid date';
    return div;
  }

  const now = DateTime.now();
  const diffInMinutes = now.diff(dateTime, 'minutes').minutes;
  const diffInDays = now.diff(dateTime, 'days').days;

  // Display "just now" if less than 1 minute has passed
  if (diffInMinutes < 1) {
    div.innerHTML = 'Just now';
  }
  // Display formatted date if more than 7 days have passed
  else if (diffInDays > 7) {
    div.innerHTML = dateTime.toFormat('MMM dd, yyyy'); // e.g., Oct 05, 2024
  }
  // Otherwise, display relative time (e.g., 1 hour ago, 3 days ago)
  else {
    div.innerHTML = dateTime.toRelative(); // Use Luxon's toRelative directly
  }

  return div;
}
