import CampaignsMetricsCell from './campaigns_metrics_cell.svelte';

/**
 * Campaign metrics cell
 */
function campaignsMetricsCell(cell) {
  const div = document.createElement('div');

  new CampaignsMetricsCell({
    target: div,
    props: {
      campaignId: cell.getData().id ?? '',
      items: [
        { title: cell.getData().total_delivered, description: 'Delivered' },
        { title: cell.getData().total_opened, description: 'Opened' },
        { title: cell.getData().total_clicked, description: 'Clicked' },
      ],
    },
  });

  return div;
}

export default { campaignsMetricsCell };
