import { Controller } from '@hotwired/stimulus';
import tippy from 'tippy.js';

export default class extends Controller {
  static values = {
    helpText: String,
  };

  connect() {
    tippy(`#${this.element.id}`, {
      content: this.helpTextValue,
    });
  }
}
