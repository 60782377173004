import BaseController from './base_controller';

export default class extends BaseController {
  static targets = ['checkbox', 'counter'];

  static values = { oneChecked: String, manyChecked: String, zeroChecked: String };

  connect() {
    this.updateCounterNumber();
  }

  updateCounterNumber() {
    const checkboxesChecked = this.checkboxTargets.filter((checkbox) => checkbox.checked).length;
    const manyChecked = this.manyCheckedValue.replace('{{count}}', checkboxesChecked);
    const message =
      // eslint-disable-next-line no-nested-ternary
      checkboxesChecked < 1 ? this.zeroCheckedValue : checkboxesChecked === 1 ? this.oneCheckedValue : manyChecked;

    this.counterTarget.innerHTML = message;
  }
}
