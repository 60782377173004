
<script>

  import UserIcon from "@components/sui/icon/svgs/UserIcon.svelte"
  
  export let contactListName = ''
  export let contactListSize = 0
  
  </script>
  
  
  
  <div class="h-full flex items-center gap-2 w-full">
    <span class="truncate">
      {contactListName}
    </span>
  
    <!-- List size info -->
    <div class="flex items-center space-x-1 flex-shrink-0">
      <div class="sui-icon xs text-base-content">
        <UserIcon/>
      </div>
      <span class="text-xs text-base-content">{contactListSize}</span>
    </div>
  </div>
  