import { Controller } from '@hotwired/stimulus';
import { leave } from 'el-transition';

export default class extends Controller {
  static targets = ['menu', 'icon'];

  toggleItems(event) {
    if (this.iconTarget !== event.target && !this.iconTarget.contains(event.target)) {
      this.hideItems();
      return;
    }
    this.menuTarget.classList.toggle('hidden');
  }

  hideItems() {
    leave(this.menuTarget);
  }
}
