import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    target: String,
    integrationName: String,
  };

  onClick(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    window.open(
      this.targetValue,
      this.integrationNameValue,
      'location,status,scrollbars,resizable,width=800,height=800',
    );
  }
}
