import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    autoDismiss: Boolean,
  };

  connect() {
    if (this.autoDismissValue) {
      setTimeout(() => {
        this.dismissAlert();
      }, 3000);
    }
  }

  dismissAlert() {
    this.element.style.display = 'none';
  }
}
