import { Controller } from '@hotwired/stimulus';
import { edit_smart_tag_templates_snippet_instruction_path } from '@root/routes';
import { Turbo } from '@hotwired/turbo-rails';

export default class extends Controller {
  static values = {
    snippetId: Number,
  };

  onClick() {
    const url = edit_smart_tag_templates_snippet_instruction_path(this.snippetIdValue);
    Turbo.visit(url, { frame: 'modal' });
  }
}
