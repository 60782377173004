import { logger } from '@root/utilities/logger';
import BaseController from './base_controller';

export default class extends BaseController {
  static targets = ['modal', 'backdrop', 'turbo_frame'];

  connect() {
    logger.debug('Modal controller connected.');
  }

  initialize() {
    super.initialize();
    this.element.addEventListener('turbo:frame-render', () => {
      this.open();
    });
  }

  load(src, onFrameLoaded = null) {
    this.turbo_frameTarget.src = src;
    const listener = this.turbo_frameTarget.addEventListener('turbo:frame-load', () => {
      if (onFrameLoaded) {
        onFrameLoaded();
      }
      this.open();
      // Unsubscribe listener:
      this.turbo_frameTarget.removeEventListener('turbo:frame-load', listener);
    });
  }

  open(e) {
    logger.debug('Opening modal');
    this.backdropTarget.classList.remove('hidden');
    this.modalTarget.classList.remove('hidden');

    if (e) {
      e.preventDefault();
    }
  }

  close(e) {
    this.modalTarget.classList.add('hidden');
    this.backdropTarget.classList.add('hidden');

    if (e) {
      e.preventDefault();
    }
  }
}
