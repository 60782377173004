<script>

  import SkeletonCell from "@components/sui/table/formatters/baseline/skeleton_cell/skeleton_cell.svelte";
  import { logger } from "@root/utilities/logger";

  /**
   * Items to display
   * @example [{ title: 'Edit', description: '/something' }, ...}]
   */
  export let items = []

  export let campaignId;

  // Fetch the data for the items
  async function fetchMetrics() {

    await fetch(`/campaigns/${campaignId}/completed_stats`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json(); // Parse the response as JSON
      })
      .then(data => {
        // eslint-disable-next-line camelcase
        const { total_clicks, total_delivered, total_opened } = data
        // eslint-disable-next-line camelcase
        const values = [ total_clicks, total_delivered, total_opened ]
        items.forEach((element, index) => {
          items[index] = {
            ...items[index],
            title: values[index] ?? "N/A",
          }
        })
      })
      .catch(error => {
        logger.debug('There was a problem with the fetch operation:', error);
      });
  }

  fetchMetrics()

</script>

<div class="w-full items-center flex flex-row gap-8">

  {#each items as item}
    <div id="{item.description.toLowerCase()}_{campaignId}" class="flex flex-col gap-0.5">
      <div class="h-5">
        {#if item.title === null || item.title === undefined}
          <!-- <span class={`loading spinner-loader h-5 w-5 transition duration-400`}></span> -->
          <SkeletonCell/>
        {:else}
          <span class="font-semibold text-sm">{item.title}</span>
        {/if}
      </div>
      <span class="text-xs text-base-note">{item.description}</span>
    </div>
  {/each}

</div>