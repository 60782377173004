import { Controller } from '@hotwired/stimulus';
import { new_audiences_smart_tag_templates_segmentation_instructions_path } from '../../javascript/routes';

export default class extends Controller {
  static targets = ['form', 'groups', 'submit'];

  addNewGroup(event) {
    event.preventDefault();
    const url = new_audiences_smart_tag_templates_segmentation_instructions_path();
    this.formTarget.action = url;
    this.formTarget.method = 'PATCH';
    this.groupsTargets.forEach((group) => {
      group.required = false;
    });

    this.submitTarget.click();
  }
}
