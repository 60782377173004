import { pino } from 'pino';

const logger = pino({
  level: window.Singulate?.logLevel ?? 'debug',
  browser: {
    asObject: true,
    formatters: {
      level: (label) => ({ level: label }),
    },
  },
});

export { logger };
