import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['dropdownContent', 'filterInput', 'option', 'hiddenInput'];

  connect() {
    this.filterInputTargetValueWas = '';

    this.mappedOptionTargets = {};
    // Optimization for large lists
    this.optionTargets.forEach((target) => {
      this.mappedOptionTargets[target.dataset.value] = target;
    });
  }

  filterOptions(event) {
    const filter = event.target.value.toLowerCase();

    this.optionTargets.forEach((option) => {
      const optionText = option.dataset.name.toLowerCase();
      option.classList.toggle('hidden', !optionText.includes(filter));
    });
  }

  selectOption(event) {
    // this should be the last sideeffect of click
    event.stopImmediatePropagation();
    const eventParamValue = event.params.value.toString();
    const selectedOption = this.mappedOptionTargets[eventParamValue];

    this.filterInputTarget.value = event.params.name;

    this.hiddenInputTarget.value = event.params.value;
    this.hiddenInputTarget.dispatchEvent(new Event('change'));

    this.filterInputTargetValueWas = event.params.name;

    selectedOption.blur();

    this.optionTargets.forEach((element) => {
      element.classList.remove('bg-blue-500', 'text-white');
    });
    selectedOption.classList.add('bg-blue-500', 'text-white');
  }

  clearFilterInput() {
    this.filterInputTarget.value = '';

    this.optionTargets.forEach((option) => {
      option.classList.remove('hidden');
    });
  }

  resetFilterInput(e) {
    if (e.relatedTarget) {
      if (this.element.contains(e.relatedTarget)) {
        return;
      }
    }

    this.filterInputTarget.value = this.filterInputTargetValueWas;
  }
}
