<script>
  import { createEventDispatcher } from "svelte";
  import SearchIcon from "@components/sui/icon/svgs/SearchIcon.svelte";
  import XaCloseIcon from "@components/sui/icon/svgs/XaCloseIcon.svelte";

  const dispatch = createEventDispatcher();
  export let placeholder = 'Search...';
  export let totalMatches = null;
  export let showResultsLabel = false;
  export let isLoading = false;
  let value = '';

  function handleInput(event) {
    value = event.target.value;
    dispatch('input', { value });
    isLoading = true;
  }

  function clearInput() {
    value = '';
    dispatch('input', { value });
    isLoading = true;
  }

  dispatch('loadingComplete');
</script>

<!-- Search with results label -->
<div class="flex flex-row items-center space-x-8">
  <!-- Search -->
  <div class="bg-base-100 input input-bordered flex items-center text-sm gap-2 p-0 h-fit py-2 w-full max-w-[300px]">
    <!-- Icon + Spinner -->
    <div class="pl-5 pr-3 w-6 h-6 flex items-center justify-center">
      <span class={`absolute loading spinner-loader h-5 w-5 transition duration-400 ${isLoading ? '' : 'opacity-0'}`}></span>
      <div class={`sui-icon base ${isLoading ? '!opacity-0' : 'opacity-100'} transition duration-200`}>
        <SearchIcon />
      </div>
    </div>

    <!-- Input and Clear Icon -->
    <div class="relative w-full">
      <!-- Input -->
      <input
        autocomplete="off"
        bind:value
        class="w-full transition duration-100 pr-10"
        on:input={handleInput}
        placeholder={placeholder}
        type="text"
      />

      <!-- Clear search button -->
      {#if value}
        <button
          class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
          on:click={clearInput}
        >
          <span class="sui-icon base opacity-100 transition duration-200">
            <XaCloseIcon />
          </span>
        </button>
      {/if}
    </div>
  </div>

  <!-- Total Matches Display -->
  <p class="text-sm">
    {#if showResultsLabel}
      {#if totalMatches > 1}
        {totalMatches} results
      {:else if totalMatches === 1}
        {totalMatches} result
      {:else}
        No results found
      {/if}
    {/if}
  </p>
</div>
