import { logger } from '@root/utilities/logger';
import { Controller } from '@hotwired/stimulus';
import { populateTurboFrame } from '@root/utilities/utils';
import { edit_smart_tag_templates_segmentation_instruction_path } from '@root/routes';

export default class SegmentationController extends Controller {
  static values = {
    id: String,
    groupSize: Number,
  };

  static targets = ['group'];

  get selectedGroup() {
    return this.groupTargets.find((x) => x.dataset.groupSelected === 'true');
  }

  connect() {
    logger.debug('Connected group');
  }

  onSwitch(event) {
    const step = parseInt(event.target.dataset.step, 10);
    const { selectedGroup } = this;
    const selectedIndex = Number(this.selectedGroup.dataset.groupIndex);
    const nextIndex = (selectedIndex + step + this.groupSizeValue) % this.groupSizeValue;
    selectedGroup.dataset.groupSelected = 'false';

    const nextSelected = this.groupTargets.find((x) => Number(x.dataset.groupIndex) === nextIndex);
    nextSelected.dataset.groupSelected = 'true';
  }

  onEdit() {
    const url = edit_smart_tag_templates_segmentation_instruction_path(this.idValue);
    // This requires this function rather than Turbo.visit because we require this request
    // To be a turbo-format as we are not updating the modal.
    populateTurboFrame(url);
  }

  updateColors(nextIndex) {
    const newColor = this.colors[nextIndex];
    logger.debug(`new: ${newColor}`);
    this.groupTargets.forEach((group) => {
      group.querySelector('.segmentation-panel').style.setProperty('--color-segmentation', newColor);
      group.querySelector('.segmentation-context').style.setProperty('--color-segmentation', newColor);
    });
  }
}
