import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['dropdown', 'skippableCell'];

  static values = { attributes: Object };

  connect() {
    this.updateSkippedColumns();
    this.updateAllOptions();
  }

  updateMappings() {
    // Captures all selected values
    const selectedValues = this.dropdownTargets.map((target) => target.value);
    // Updates all attributes
    const duplicatedAttributes = JSON.parse(JSON.stringify(this.attributesValue));
    Object.keys(this.attributesValue).forEach((key) => {
      duplicatedAttributes[key].utilized = selectedValues.some((value) => value === key);
    });
    this.attributesValue = duplicatedAttributes;
    this.updateSkippedColumns();
    this.updateAllOptions();
  }

  updateSkippedColumns() {
    const skippedHeaders = {};

    this.dropdownTargets.forEach((dropdown) => {
      const { header } = dropdown.dataset;
      skippedHeaders[header] = dropdown.value === 'skip';
    });

    this.skippableCellTargets.forEach((skippableCell) => {
      const { header } = skippableCell.dataset;
      skippableCell.classList.toggle('text-gray-400', skippedHeaders[header]);
    });
  }

  updateAllOptions() {
    this.dropdownTargets.forEach((target) => {
      target.querySelectorAll('option').forEach((option) => {
        if (option.value === 'skip' || option.value === 'create' || !option.value) return;
        const { utilized } = this.attributesValue[option.value];
        if (option.selected) {
          option.disabled = false;
        } else {
          option.disabled = utilized;
        }
      });
    });
  }

  dropdownTargetDisconnected() {}
}
