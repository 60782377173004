<script>

  export let DropdownIcon = null;

  /**
   * Array of objects to fill the dropdown with.
   * @example [
   * { label: 'Edit', url: '/something', icon: EditIcon }, // Icon needs to be imported in the parent component
   * { label: 'Delete', url: '/something_else' },
   * ]
   */
  export let Items = [];
  export let aboveContent;

  // Variant color map
  const TEXT_COLOR_MAP = {
    default: 'text-base-content hover:text-base-content',
    primary: 'text-primary-default hover:text-primary-content',
    error: 'text-error-content hover:text-error-content',
    success: 'text-success-content hover:text-success-content',
    warning: 'text-warning-content hover:text-warning-content',
  };

</script>

<div>

  <div class="dropdown dropdown-end {aboveContent ? 'dropdown-above' : ''}">
    <div class="w-8 h-8 flex items-center justify-center rounded-md hover:bg-base-300 transition duration-75"
         role="button"
         tabindex="0">
    {#if DropdownIcon}
      <div class="sui-icon sm"><svelte:component this={DropdownIcon} class="sui-icon sm" /></div>
    {/if}
    </div>
    <ul class="dropdown-content menu bg-base-100 rounded-box z-50 w-52 p-2 shadow" tabindex="-1">
        {#each Items as Item}
          <li>
            <a href={Item.url}
               data-method={Item.method}
               data-turbo-method={Item.method}
               data-turbo-frame={Item.frame}
               class={`hover:bg-base-200 rounded-lg ${Item.color ? TEXT_COLOR_MAP[Item.color] ?? TEXT_COLOR_MAP.default : TEXT_COLOR_MAP.default }`}>
              {#if Item.icon}
                <div class="sui-icon sm"><svelte:component this={Item.icon} class="sui-icon sm" /></div>
              {/if}
              {Item.label}
            </a>
        {/each}
    </ul>
  </div>

</div>