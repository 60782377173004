import addElement from '@components/utils/add_element';
import { logger } from '@root/utilities/logger';
import ModalController from '../modal_controller';

// Connects to data-controller="unsubscsribe-instruction"
export default class extends ModalController {
  static targets = ['modal', 'backdrop', 'rules', 'turbo_frame', 'groups', 'form'];

  connect() {
    this.campaignId = this.data.get('campaignId');
    logger.debug('Connected to unsub');
  }

  create(e) {
    addElement({ form: e.target }).then(() => {
      this.close(e);
    });
    e.preventDefault();
  }
}
