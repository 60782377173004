import { Controller } from '@hotwired/stimulus';

export default class Delete extends Controller {
  delete(e) {
    // Firing an event that will be listened to by the editor controller
    // Will delete the component from tiny + trigger an autosave
    const event = new CustomEvent('delete-smart-tag-template', {
      detail: {
        id: e.params.id,
      },
    });
    window.dispatchEvent(event);
  }
}
