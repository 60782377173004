import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['topAttributeToggleIcon', 'attributesList'];

  toggleAttributesList() {
    this.attributesListTarget.classList.toggle('hidden');
    this.topAttributeToggleIconTarget.classList.toggle('rotate-180');
    this.updateTopAttributesCookie(this.attributesListTarget.classList);
  }

  updateTopAttributesCookie(classList) {
    const topAttributesState = classList.contains('hidden') ? 'hidden' : 'visible';

    document.cookie = `campaign_editor_show_top_attributes=${topAttributesState}; SameSite=Lax; path=/;`;
  }
}
