import { logger } from '@root/utilities/logger';
import tinymce from 'tinymce/tinymce';
import csrfHeaders from '../../javascript/utilities/csrfHeaders';

async function getElementContent(id) {
  // Fetch the template from the server (expecting an HTML response)
  const response = await fetch(`/smart_tag_templates/${id}`);
  return response.text();
}

export default async function addElement({ form, json, onlyRender = false }) {
  let data;
  if (form) {
    const formData = new FormData(form);
    //  Fetches for a JSON document.
    const response = await fetch(form.action, {
      method: form.method,
      body: formData,
      headers: {
        Accept: 'application/json',
        ...csrfHeaders(),
      },
    });
    data = await response.json();
  } else {
    data = json;
  }
  const elementId = `smart_tag_template-${data.id}`;

  const elementContent = await getElementContent(data.id);

  const trimmedContent = elementContent.trim();

  if (onlyRender) {
    return trimmedContent;
  }

  const iframeSegment = document.getElementById('segment-iframe');
  let activeEditorInstance = tinymce.activeEditor;
  if (iframeSegment) {
    const tinymceInstance = iframeSegment.contentWindow.tinymce;
    if (typeof tinymceInstance !== 'undefined' && tinymceInstance.activeEditor) {
      activeEditorInstance = tinymceInstance.activeEditor;
    }
  }

  // Insert the HTML at the current selection (caret position)
  activeEditorInstance.insertContent(trimmedContent);
  const iframeDocument = activeEditorInstance.iframeElement.contentWindow.document;

  const element = iframeDocument.getElementById(elementId);

  // If there are no paragraphs after the one where the element is inserted, add an empty paragraph.
  // Find closest paragraph element
  let parentParagraph = null;
  // If the element is a div - it is its own parent:
  if (element.tagName === 'DIV') {
    parentParagraph = element;
  } else {
    parentParagraph = element.closest('p');
  }
  if (!parentParagraph) {
    logger.error('No parent paragraph found');
  }
  // Find the next paragraph element
  const nextParagraph = parentParagraph.nextElementSibling;
  if (!nextParagraph) {
    logger.debug('No next paragraph found');
  } else {
    logger.debug(nextParagraph, 'Next paragraph found');
  }

  if (!nextParagraph && !iframeSegment) {
    // Adds an empty paragraph element below the smart-tag
    const emptyP = iframeDocument.createElement('p');
    const brElement = iframeDocument.createElement('br');
    brElement.setAttribute('data-mce-bogus', '1');
    emptyP.appendChild(brElement);
    const currP = element.parentNode;
    currP.parentNode.insertBefore(emptyP, currP.nextSibling);
  }
  return data;
}

export async function reloadElement(id, iframe) {
  const elementId = `smart_tag_template-${id}`;
  const elementContent = await getElementContent(id);
  // Trim the content to remove surrounding white spaces:
  const trimmedContent = elementContent.trim();

  if (iframe) {
    const iframeDocument = iframe.contentWindow.document;
    const element = iframeDocument.getElementById(elementId);

    if (element === null) return false;

    element.outerHTML = trimmedContent;
    logger.debug({ element }, `Reloaded element, With content ${trimmedContent}`);
  } else {
    const iframeSegment = document.getElementById('segment-iframe');
    let activeEditorInstance = tinymce.activeEditor;
    if (iframeSegment) {
      const tinymceInstance = iframeSegment.contentWindow.tinymce;
      if (typeof tinymceInstance !== 'undefined' && tinymceInstance.activeEditor) {
        activeEditorInstance = tinymceInstance.activeEditor;
      }
    }

    const { iframeElement } = activeEditorInstance;

    if (iframeElement === null) return false;

    const iframeDocument = iframeElement.contentWindow.document;
    const element = iframeDocument.getElementById(elementId);

    if (element === null) return false;

    element.outerHTML = trimmedContent;
    logger.debug({ element }, `Reloaded element, With content ${trimmedContent}`);
  }
  return true;
}
