<script>

  export let campaignId = ''
  export let campaignName = ''
  export let campaignStatus = ''

  const campaignEditorUrl = `/campaigns/${campaignId}/campaign_workflow/current_state`;

  // Mock data assignment
  /* campaignStatus = ['draft', 'generating', 'generated'][Math.floor(Math.random() * 3)];

  const SAMPLE_CAMPAIGN_NAMES = [
    'Francis test',
    'Team Update for week 20214-10-10',
    'Copy of Nico: Test 1',
    'Max super cool campaign test',
    'Test dummy data',
    'Team update for last week',
    'Testing HTML for Maestra',
    'Gosia email campaign demo',
  ];

  campaignName = SAMPLE_CAMPAIGN_NAMES[Math.floor(Math.random() * SAMPLE_CAMPAIGN_NAMES.length)]; */


  let statusDisplayClass = '';
  let statusDisplayText = '';

  switch (campaignStatus) {
    case 'draft': // Nothing required
      break;
    case 'generating':
      statusDisplayClass = 'bg-base-200 pr-1.5 text-base-content';
      statusDisplayText = 'Generating';
      break;
    case 'generated':
      statusDisplayClass = 'bg-info-default/50 text-info-content';
      statusDisplayText = 'Ready to send';
      break;
    default:
      break;
  }

</script>




<div class="flex items-center gap-4 w-full">
  <a class="font-semibold truncate text-primary-default hover:underline"
    href={campaignEditorUrl}
  >
    {campaignName}
  </a>

  <!-- Pill -->
  {#if statusDisplayText !== ''}
    <span class="flex font-semibold items-center gap-2 px-2 py-1 rounded-full
                 text-xs w-fit whitespace-nowrap flex-shrink-0 {statusDisplayClass}">
      {statusDisplayText}
      {#if campaignStatus === 'generating'}
        <span class="loading spinner-loader h-3.5 w-3.5"></span>
      {/if}
    </span>
  {/if}
</div>