import BaseController from './base_controller';

// Connects to data-controller="reset-form"
export default class extends BaseController {
  reset(e) {
    if (e.detail.success) {
      this.element.reset();
    }
  }
}
