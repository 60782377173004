import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="turbo-modal"
export default class TurboModalController extends Controller {
  static values = { closeOnSubmit: Boolean };

  connect() {
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        this.hideModal();
      }
    });
    document.addEventListener('turbo:submit-end', (event) => {
      if (event.detail.success && this.closeOnSubmitValue) {
        this.hideModal();
      }
    });
  }

  hideModal() {
    if (!this.element.parentElement) {
      return;
    }

    if (this.element.parentElement.id === 'modal') {
      this.element.parentElement.removeAttribute('src'); // it might be nice to also remove the modal SRC
      [].forEach.call(this.element.parentElement.children, (el) => {
        el.remove();
      });
      this.element.remove();
    }
  }
}
