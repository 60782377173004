import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import { logger } from '@root/utilities/logger';
import csrfHeaders from '@root/utilities/csrfHeaders';
import { toast } from '../toast_controller';
import { reject_smart_tag_templates_summary_instruction_path } from '../../routes';
// Connects to data-controller="fine-tuning"
export default class extends Controller {
  static values = {
    smartTagId: Number,
    smartTagTemplateId: Number,
  };

  static targets = ['textarea', 'edit', 'approve'];

  get generated() {
    return Boolean(this.textareaTarget?.dataset.generated);
  }

  connect() {
    logger.debug(`generated: ${this.generated}`);
  }

  textareaTargetConnected() {
    logger.debug(`Textarea connected ${this.generated}`);
    // TODO: Nasty dependency on the default content:
    // Ideally pick up the live state of the generating text area some other way.
    if (this.textareaTarget.innerHTML === 'Generating...') {
      if (this.hasApproveTarget) {
        this.approveTarget.classList.add('hidden');
      }
    } else if (this.hasApproveTarget) {
      this.approveTarget.classList.remove('hidden');
    }
  }

  enableEditing(event) {
    this.requiresGeneration(() => {
      if (event.params.value) {
        this.disableEditing();
        return;
      }
      this.textareaTarget.disabled = false;
      this.editTarget.classList.remove('hidden');
      this.approveTarget.classList.add('hidden');
    });
  }

  disableEditing() {
    this.textareaTarget.disabled = true;
    this.editTarget.classList.add('hidden');
    this.approveTarget.classList.remove('hidden');
  }

  requiresGeneration(func) {
    if (this.generated) {
      func();
    } else {
      toast({
        message: `Please wait for the text to finish generating.`,
        type: 'error',
      });
    }
  }

  reject() {
    this.enableEditing();
  }

  commitEdit() {
    this.disableEditing();
    logger.debug('Committed edit - going for rejection');
    fetch(reject_smart_tag_templates_summary_instruction_path(this.smartTagTemplateIdValue, this.smartTagIdValue), {
      method: 'PATCH',
      headers: {
        Accept: 'text/vnd.turbo-stream.html, text/html, application/xhtml+xml',
        'Content-Type': 'application/json',
        ...csrfHeaders(),
      },
    })
      .then((response) => response.text())
      .then((html) => Turbo.renderStreamMessage(html));
  }

  verify() {
    // This should approve and update the streak
  }
}
