import { Controller } from '@hotwired/stimulus';
import debounce from 'debounce';
import { preview_smart_tag_templates_liquid_instruction_path } from '@root/routes';

export default class extends Controller {
  static targets = ['sampleContacts', 'inputField'];

  static values = { smartTagTemplateId: Number };

  connect() {
    this.debouncedSetNewSampleSrc = debounce(this.setNewSampleSrc, 500).bind(this);
  }

  samplePreview() {
    if (this.hasSampleContactsTarget) {
      this.sampleContactsTarget.querySelectorAll('li').forEach((sampleContactTarget) => {
        sampleContactTarget.innerText = 'Calculating...';
        sampleContactTarget.classList.remove('text-red-700');
      });
      this.debouncedSetNewSampleSrc();
    }
  }

  setNewSampleSrc() {
    const instruction = this.inputFieldTarget.value;
    this.sampleContactsTarget.src = preview_smart_tag_templates_liquid_instruction_path(this.smartTagTemplateIdValue, {
      instruction,
    });
  }
}
