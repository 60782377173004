import BaseController from './base_controller';

export default class extends BaseController {
  static targets = ['emailiFrame'];

  connect() {
    document.addEventListener('turbo:load', () => this.resizeIframe());
  }

  resizeIframe() {
    if (this.emailiFrameTarget?.contentWindow) {
      const newHeight = `${this.emailiFrameTarget.contentWindow.document.body.scrollHeight + 42}px`;
      this.emailiFrameTarget.style.height = newHeight;
    }
  }
}
