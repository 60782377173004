<script>
  import ChevronDownIcon from "@components/sui/icon/svgs/ChevronDownIcon.svelte";
  import ChevronSelectorVerticalIcon from "@components/sui/icon/svgs/ChevronSelectorVerticalIcon.svelte";
  import ChevronUpIcon from "@components/sui/icon/svgs/ChevronUpIcon.svelte";

  export let direction = "none";
</script>

<div>
  <div class="sui-icon xs">
    {#if direction === 'asc'}
      <ChevronUpIcon />
    {:else if direction === 'desc'}
      <ChevronDownIcon />
    {:else}
      <ChevronSelectorVerticalIcon />
    {/if}
  </div>
</div>
