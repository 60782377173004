import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import { show_inbox_email_path } from '@root/routes';

export default class extends Controller {
  static outlets = ['email-preview'];

  static targets = ['phoneMode'];

  toggleTags(event) {
    this.emailPreviewOutlet.toggleTags(event.target.checked);
  }

  toggleInbox(event) {
    const emailId = this.emailPreviewOutletElement.dataset.emailPreviewEmailIdValue;
    const newValue = event.target.checked ? 'true' : 'false';
    document.cookie = `email_preview_show_inbox=${newValue}; SameSite=Lax; path=/;`;

    Turbo.visit(show_inbox_email_path(emailId), { frame: `inbox_preview_email_${emailId}` });
  }

  togglePhoneMode(event) {
    this.emailPreviewOutlet.togglePhoneMode(event.params.phoneMode);

    this.phoneModeTargets.forEach((btn) => btn.classList.remove('btn-primary'));
    event.currentTarget.classList.add('btn-primary');
  }
}
