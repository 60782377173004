// General formatters
import relativeDateTimeCellFormatter from './baseline/relative_datetime/formatter';
import linkCellFormatter from './baseline/link/formatter';
import skeletonCellFormatter from './baseline/skeleton_cell/formatter';
// eslint-disable-next-line
import formattersFromComponents from "../../../../components/**/*_formatter.js";

let componentFormatters = {};

formattersFromComponents.forEach((formatter) => {
  componentFormatters = { ...componentFormatters, ...formatter.module.default };
});

/**
 * Formatters for the Tabulator table
 */
const allFormatters = {
  relativeDateTimeCell: relativeDateTimeCellFormatter,
  skeletonCell: skeletonCellFormatter,
  linkCell: linkCellFormatter,
  ...componentFormatters,
};

export default allFormatters;
