import PencilOneIcon from '@components/sui/icon/svgs/PencilOneIcon.svelte';
import DeleteIcon from '@components/sui/icon/svgs/DeleteIcon.svelte';
import CopyIcon from '@components/sui/icon/svgs/CopyIcon.svelte';
import DotsVerticalIcon from '@components/sui/icon/svgs/DotsVerticalIcon.svelte';
import Dropdown from '@components/sui/table/formatters/baseline/dropdown/dropdown.svelte';
import { campaign_path, edit_campaign_path, start_duplicate_campaign_path } from '@root/routes';

/**
 * Campaign kebab menu for Drafts
 */
function campaignsDraftActionsCell(cell) {
  const div = document.createElement('div');

  // Get the cell element and make it show the overflow to display the kebab menu
  cell.getElement().style.overflow = 'visible';
  const table = cell.getTable();
  const rowIndex = cell.getRow().getPosition();
  const totalRows = table.getRows().length;
  const aboveContent = totalRows <= 3 ? false : rowIndex >= totalRows - 1;

  new Dropdown({
    target: div,
    props: {
      DropdownIcon: DotsVerticalIcon,
      aboveContent,
      Items: [
        {
          label: 'Edit',
          icon: PencilOneIcon,
          url: edit_campaign_path(cell.getData().id),
        },
        {
          label: 'Duplicate',
          icon: CopyIcon,
          url: start_duplicate_campaign_path(cell.getData().id),
          frame: 'modal',
        },
        {
          label: 'Delete',
          icon: DeleteIcon,
          url: campaign_path(cell.getData().id),
          color: 'error',
          method: 'delete',
        },
      ],
    },
  });

  return div;
}

export default { campaignsDraftActionsCell };
