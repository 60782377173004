import { errorHandler } from '@root/utilities/error_tracking';
import BaseController from './base_controller';

// Connects to data-controller="tabs"
export default class extends BaseController {
  static values = { active: Number, length: Number };

  static targets = ['tab', 'tabContent'];

  activate(e) {
    this.activeValue = e.params.index;
  }

  activeValueChanged() {
    try {
      const activeTab = this.tabTargets[this.activeValue];
      if (activeTab) {
        this.tabTargets.forEach((tab) => {
          tab.classList.remove('tab-active');
        });

        activeTab.classList.add('tab-active');

        if (this.activeValue === 0) {
          // set the left border to be 0
          activeTab.style.borderLeft = '0';
        }

        if (this.lengthValue && this.lengthValue === this.activeValue + 1) {
          activeTab.style.borderRight = '0';
        }

        this.tabContentTargets.forEach((tabContent) => {
          tabContent.classList.add('hidden');
        });

        this.tabContentTargets[this.activeValue].classList.remove('hidden');
      }
    } catch (e) {
      errorHandler(e, 'Error in updating tabs', {
        tabContentTargets: this.tabContentTargets,
        activeValue: this.activeValue,
        tabTargets: this.tabTargets,
      });
    }
  }
}
