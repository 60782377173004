import { Controller } from '@hotwired/stimulus';
import addElement from '@components/utils/add_element';
import consumer from '@root/channels/consumer';
import { logger } from '@root/utilities/logger';
import { featureFlagEnabled } from '@root/utilities/utils';

// Connects to data-controller="fine-tune"
export default class extends Controller {
  static values = {
    smartTagTemplateId: Number,
    addElementToEditor: Boolean,
    smartTagId: Number,
    smartTagGeneratedState: Boolean,
  };

  static outlets = ['campaign-editor-grapes'];

  smartTagSubscrption = null;

  connect() {
    if (this.addElementToEditorValue) {
      const html = this.addElement();

      if (this.isGrapeEditor()) {
        this.campaignEditorGrapesOutlet.receiveSmartTag(html);
      }
    }
    this.connectToSmartTagChannel();
  }

  isGrapeEditor() {
    return featureFlagEnabled('rich_html_editor');
  }

  addElement() {
    return addElement({ json: { id: this.smartTagTemplateIdValue }, onlyRender: this.isGrapeEditor() });
  }

  connectToSmartTagChannel() {
    logger.debug({ message: 'Setting up generation', smart_tag_id: this.smartTagIdValue });
    this.smartTagSubscrption = consumer.subscriptions.create(
      {
        channel: 'SmartTagsChannel',
        smart_tag_id: this.smartTagIdValue,
      },
      {
        connected: this.onChannelConnected.bind(this),
        disconnected: this.onChannelDissonnected.bind(this),
        received: this.onChannelReceived.bind(this),
      },
    );
  }

  onChannelConnected() {
    logger.debug({ message: 'Connected to SmartTagsChannel' });
    if (!this.smartTagGeneratedStateValue) {
      logger.debug({ message: 'Requesting smart tag generation' });
      this?.smartTagSubscrption.perform('queue_smart_tag_generation');
    }
  }

  onChannelDissonnected() {
    logger.debug({ message: 'Disconnected from SmartTagsChannel' });
  }

  onChannelReceived(data) {
    logger.debug({ message: 'Received data from SmartTagsChannel', data });
  }
}
