import { Controller } from '@hotwired/stimulus';
import tinymce from 'tinymce/tinymce';
import { done_segments_path } from '@root/routes';
import { clearTurboFrame, featureFlagEnabled } from '@root/utilities/utils';
import addElement, { reloadElement } from '../utils/add_element';

export default class extends Controller {
  static targets = ['iframe'];

  static outlets = ['campaign-editor-grapes'];

  static values = { campaignId: Number, smartTagTemplateId: Number };

  connect() {
    this.iframeTarget.onload = () => {
      if (
        this.iframeTarget.contentWindow.location.pathname ===
        done_segments_path(this.campaignIdValue, this.smartTagTemplateIdValue)
      ) {
        if (!featureFlagEnabled('rich_html_editor')) {
          const editor = tinymce.activeEditor;
          const element = editor.contentWindow.document.getElementById(
            `smart_tag_template-${this.smartTagTemplateIdValue}`,
          );

          if (!element) {
            addElement({
              json: {
                id: this.smartTagTemplateIdValue,
              },
            });
          } else {
            reloadElement(this.smartTagTemplateIdValue);
          }
        } else {
          const iframe = this.campaignEditorGrapesOutlet.editor.Canvas.getFrameEl();
          const element = iframe.contentWindow.document.getElementById(
            `smart_tag_template-${this.smartTagTemplateIdValue}`,
          );
          if (!element) {
            const html = addElement({
              json: {
                id: this.smartTagTemplateIdValue,
              },
              onlyRender: true,
            });
            this.campaignEditorGrapesOutlet.receiveSmartTag(html);
          } else {
            reloadElement(this.smartTagTemplateIdValue, iframe);
          }
        }

        clearTurboFrame('segmentation-page');
      }
    };
  }
}
