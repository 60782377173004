import { TurboMount } from 'turbo-mount';
import { registerComponent } from 'turbo-mount/svelte4';
import icons from './svgs/*.svelte';

// This is a dummy controller that takes care of registering all of our icons when we boot the rest of the stimulus
// application

const turboMount = new TurboMount({});
icons.forEach((icon) => {
  const name = icon.name.split('--').pop().replace('.svelte', '');
  registerComponent(turboMount, name, icon.module.default);
});

export default turboMount;
