import IconButton from '@components/sui/table/formatters/baseline/icon_button/icon_button.svelte';
import PencilOneIcon from '@components/sui/icon/svgs/PencilOneIcon.svelte';
import { edit_schema_attribute_path } from '@root/routes';

/**
 * Custom formatter function for the attribute actions cell
 */
function attributeActionsCell(cell) {
  const div = document.createElement('div');

  new IconButton({
    target: div,
    props: {
      Icon: PencilOneIcon,
      url: edit_schema_attribute_path(cell.getData().id),
      dataAttributes: {
        'data-turbo-frame': 'modal',
        'data-controller': 'attribute-setup',
      },
    },
  });

  return div;
}

export default { attributeActionsCell };
