import addElement from '@components/utils/add_element';
import ModalController from '../modal_controller';
import {
  // eslint-disable-next-line camelcase
  edit_smart_tag_templates_summary_instruction_path,
} from '../../routes';

// Connects to data-controller="summary-modal"
export default class extends ModalController {
  static targets = ['modal', 'backdrop', 'rules', 'turbo_frame', 'groups', 'form', 'link'];

  connect() {
    this.campaignId = this.data.get('campaignId');
  }

  addElement(e) {
    e.preventDefault();
    const form = e.type === 'keydown' ? document.getElementById('summary-instructions-create-form') : e.target;
    addElement({ form }).then((smartTagInstructions) => {
      this.load(edit_smart_tag_templates_summary_instruction_path(smartTagInstructions.id));
    });

    setTimeout(() => {
      const modal = document.getElementById('smart_tag_template_instructions_attributes_context');
      if (modal) {
        modal.focus();
      }
    }, 200);
  }

  edit(e) {
    this.load(edit_smart_tag_templates_summary_instruction_path(e.detail.id));
  }
}
