import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import {
  edit_smart_tag_templates_snippet_instruction_path,
  generate_summary_smart_tag_templates_snippet_instructions_path,
} from '@root/routes';
import csrfHeaders from '@root/utilities/csrfHeaders';
import addSpinnerLoader from '@components/utils/add_spinner_loader';

// Connects to data-controller="settings"
export default class extends Controller {
  static targets = [
    'content',
    'header',
    'main',
    'fallbackText',
    'fullContent',
    'sendFallback',
    'sendFallbackLabel',
    'campaignId',
    'nullifyOnEmptyFullContent',
    'formContainer',
    'submitButton',
    'warningTemplate',
    'warningContainer',
    'sendFullContent',
    'fullContentTitle',
    'fullContentLoader',
  ];

  static values = { instructions: Object };

  connect() {
    this.instructions = {
      ...this.instructionsValue,
    };
    this.toggleFallback();
    this.toggleFullContent();
  }

  displayFullContent(event) {
    const content = this.contentTarget;
    const main = this.mainTarget;
    const header = this.headerTarget;
    const toggleFullContent = this.sendFullContentTarget;
    const fullContent = this.fullContentTarget;

    if (content.classList.contains('hidden')) {
      content.classList.remove('hidden');
      toggleFullContent.classList.add('pointer-events-none');
      main.classList.add('pointer-events-none');
      main.classList.add('opacity-20');
      header.classList.remove('h-36');
    } else {
      if (event.currentTarget.id !== 'apply') {
        content.children[0].value = this.instructions.full_content;
        if (!this.instructions.full_content) {
          toggleFullContent.click();
        }
      }
      this.instructions.full_content = fullContent.value;
      this.toggleFullContent(event);
      header.classList.add('h-36');
      content.classList.add('hidden');
      main.classList.remove('pointer-events-none');
      main.classList.remove('opacity-20');
      toggleFullContent.classList.remove('pointer-events-none');
    }
  }

  generateSummary(e) {
    e.preventDefault();
    if (this.fullContentTarget.value === '') return;
    this.displayFullContent(e);

    if (this.fullContentTarget.value.trim() !== '') {
      const fallback = this.sendFallbackTarget.checked && this.fallbackTextTarget.value.trim() === '';
      addSpinnerLoader('title-loader', { w: 'w-4', h: 'h-4' }, this.fullContentLoaderTarget);
      this.headerTarget.classList.add('h-36');
      fetch(generate_summary_smart_tag_templates_snippet_instructions_path(), {
        method: 'PATCH',
        body: JSON.stringify({
          full_content: this.fullContentTarget.value,
          campaign_id: this.campaignIdTarget.value,
          fallback,
        }),
        headers: {
          Accept: 'text/vnd.turbo-stream.html, text/html, application/xhtml+xml',
          'Content-Type': 'application/json',
          ...csrfHeaders(),
        },
      })
        .then((response) => response.text())
        .then((html) => Turbo.renderStreamMessage(html));
      this.fullContentTitleTarget.classList.remove('hidden');
    } else {
      this.nullifyOnEmptyFullContentTargets.forEach((target) => {
        if (target) {
          target.value = null;
          target.innerText = null;
        }
      });
      this.fullContentTitleTarget.classList.add('hidden');
    }
  }

  edit(e) {
    const url = edit_smart_tag_templates_snippet_instruction_path(e.detail.id);
    Turbo.visit(url, { frame: 'modal' });
  }

  toggleFallback() {
    if (this.sendFallbackTarget.checked) {
      this.fallbackTextTarget.required = true;
      this.fallbackTextTarget.disabled = false;
      this.sendFallbackLabelTarget.textContent = 'Send fallback text to contacts without enough data';
    } else {
      this.fallbackTextTarget.required = false;
      this.fallbackTextTarget.disabled = true;
      this.sendFallbackLabelTarget.textContent = 'Leave blank';
    }
  }

  toggleFullContent() {
    if (this.sendFullContentTarget.checked) {
      if (!this.instructions.full_content) {
        this.displayFullContent();
      } else {
        this.headerTarget.classList.add('h-36');
        this.fullContentTitleTarget.classList.remove('hidden');
      }
      this.fullContentTarget.disabled = false;
      this.nullifyOnEmptyFullContentTargets.forEach((target) => {
        target.disabled = false;
      });
    } else {
      this.fullContentTitleTarget.classList.add('hidden');
      this.fullContentTarget.disabled = true;
      this.nullifyOnEmptyFullContentTargets.forEach((target) => {
        target.disabled = true;
      });
    }
  }

  checkInstructionsChanges(event) {
    const fieldToCheck = event.params.fieldName;

    if (!this.instructions.id) return;

    if (event.currentTarget.type === 'checkbox') {
      this.handleCheckboxChange(this.instructions, fieldToCheck, event.currentTarget.checked);
    } else {
      this.handleInputChange(this.instructions, fieldToCheck, event.currentTarget.value);
    }
  }

  handleCheckboxChange(instructions, fieldToCheck, checked) {
    if (instructions[fieldToCheck] !== checked) {
      this.showWarning();
    } else {
      this.hideWarning();
    }
  }

  handleInputChange(instructions, fieldToCheck, value) {
    if (instructions[fieldToCheck] !== value) {
      this.showWarning();
    } else {
      this.hideWarning();
    }
  }

  showWarning() {
    this.warningContainerTarget.innerHTML = this.warningTemplateTarget.innerHTML;
    this.submitButtonTarget.innerHTML = 'Save and fine tune';
  }

  hideWarning() {
    this.warningContainerTarget.innerHTML = '';
    this.submitButtonTarget.innerHTML = 'Continue fine-tuning';
  }
}
