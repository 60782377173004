import Link from './link.svelte';

/**
 * A link cell formatter that can accept dynamic property urls and data
 * @param {import('tabulator-tables').CellComponent} cell Tabulator's cell component
 * @param {*} formatterParams The parameters for the formatter
 * @returns {HTMLElement}
 */
export default function linkCellFormatter(cell, formatterParams) {
  let url = formatterParams.url ?? '#';
  if (formatterParams.url?.includes('{')) {
    const parameterName = formatterParams.url.match(/{(.*?)}/);
    const parameterDataName = parameterName[1];
    if (parameterDataName) {
      const parameterData = cell.getData()[parameterDataName];
      url = formatterParams.url.replace(`{${parameterDataName}}`, parameterData ?? '');
    }
  }

  let text = formatterParams.text ?? cell.getValue();
  if (formatterParams.text?.includes('{')) {
    const parameterName = formatterParams.text.match(/{(.*?)}/);
    const parameterDataName = parameterName[1];
    if (parameterDataName) {
      const parameterData = cell.getData()[parameterDataName];
      text = formatterParams.text.replace(`{${parameterDataName}}`, parameterData ?? '');
    }
  }

  /* return createFormatter(Link, {
    url: url,
    text: text,
    dataAttributes: formatterParams.data,
    target: formatterParams.target,
  }); */

  const div = document.createElement('div');

  new Link({
    target: div,
    props: {
      url,
      text,
      dataAttributes: formatterParams.data,
      target: formatterParams.target,
    },
  });

  return div;
}
