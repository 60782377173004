import CampaignsNameCell from './campaigns_name_cell.svelte';

/**
 * Campaign name containing a status pill depending on the campaign's status
 */
function campaignsNameCell(cell) {
  const div = document.createElement('div');

  new CampaignsNameCell({
    target: div,
    props: {
      campaignId: cell.getData().id ?? '',
      campaignName: cell.getData().title ?? '',
      campaignStatus: cell.getData().state ?? '',
    },
  });

  return div;
}

export default { campaignsNameCell };
