<script>
  export let icon;
  export let key;
  export let text;
  export let label;
  function camelToKebab(str) {
    return str
      .replace(/([a-z])([A-Z])/g, '$1-$2')
      .toLowerCase();
  }

  const dataController = `turbo-mount-${camelToKebab(icon)}-icon`;
  const dataValue = `${icon}Icon`
  const dynamicAttributes = {
    [`data-turbo-mount-${camelToKebab(icon)}-icon-component-value`]: dataValue,
    "data-controller": dataController
  };

</script>

<div {...dynamicAttributes} class="sui-icon xs">
</div>
{#if label}
  <div class="flex flex-col">
  <span class="text-sm font-semibold" data-dropdown-with-icon-target="selectedOptionText">
  {text}
  </span>
  <span class="text-xs font-mono font-extralight text-left">{key}</span>
  </div>
{:else}
  <p>{text}</p>
{/if}
