import { Controller } from '@hotwired/stimulus';
import { logger } from '@root/utilities/logger';

export default class extends Controller {
  static values = { items: Array };

  static targets = ['badge', 'checkbox', 'noneSelected'];

  toggleItem(event) {
    const { value } = event.params;

    const items = this.itemsValue;

    items.forEach((item) => {
      if (item.value === value.toString()) {
        item.selected = !item.selected;
      }
    });

    this.itemsValue = items;
  }

  itemsValueChanged() {
    let anySelected = false;

    this.itemsValue.forEach((item) => {
      if (item.selected) {
        anySelected = true;
      }

      this.badgeTargets.forEach((badge) => {
        if (badge.dataset.value === item.value) {
          badge.classList.toggle('hidden', !item.selected);
        }
      });

      this.checkboxTargets.forEach((checkbox) => {
        if (checkbox.dataset.value === item.value) {
          checkbox.checked = item.selected;
        }
      });
    });

    const checkboxCheckedStatus = this.checkboxTargets.map((checkbox) => checkbox.checked);
    logger.debug({ messages: 'Checkboxes', checkboxCheckedStatus });

    this.noneSelectedTarget.classList.toggle('hidden', anySelected);
  }
}
