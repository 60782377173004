import BaseController from './base_controller';

export default class extends BaseController {
  static targets = ['menu', 'userTag'];

  toggle() {
    if (this.menuTarget.dataset.expanded === '1') {
      this.collapse();
    } else {
      this.expand();
    }
  }

  collapse() {
    this.menuTarget.classList.add('hidden');
    this.menuTarget.dataset.expanded = '0';
  }

  expand() {
    this.menuTarget.classList.remove('hidden');
    this.menuTarget.dataset.expanded = '1';
  }
}
