import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import { logger } from '@root/utilities/logger';

export default class BaseController extends Controller {
  turboEvents = [
    'turbo:click',
    'turbo:before-visit',
    'turbo:visit',
    'turbo:submit-start',
    'turbo:before-fetch-request',
    'turbo:before-fetch-response',
    'turbo:submit-end',
    'turbo:before-cache',
    'turbo:before-render',
    'turbo:before-stream-render',
    'turbo:render',
    'turbo:load',
    'turbo:frame-render',
    'turbo:frame-load',
    'turbo:fetch-request-error',
  ];

  static listeners = [];

  initialize() {
    super.initialize();
    this.debugTurbo();
  }

  connect() {
    window.addEventListener('popstate', this.navigatedBack);
  }

  debugTurbo() {
    if (this.debuggingTurbo) {
      this.turboEvents.forEach((eventName) => {
        if (BaseController.listeners.includes(eventName)) return;
        BaseController.listeners.push(eventName);
        logger.debug(`Adding listener for ${eventName}`);

        document.addEventListener(eventName, (event) => {
          logger.debug(event, event.type);
        });
      });
    }
  }

  get debuggingTurbo() {
    return window.Singulate?.debug_turbo === 'true';
  }

  navigatedBack(event) {
    // If we're in the context of turbo we want to manually visit the page on back so that we always have it rerendered
    if (event?.state?.turbo) {
      Turbo.visit(window.location.href, { action: 'replace' });
    }
  }
}
