import { Base64 } from 'js-base64';
import { Turbo } from '@hotwired/turbo-rails';
import { clear_smart_tag_editing_frame_campaigns_path } from '@root/routes';

function clearTurboFrame(frameTagName) {
  fetch(clear_smart_tag_editing_frame_campaigns_path({ frame_to_clear: frameTagName }), {
    method: 'GET',
    headers: {
      Accept: 'text/vnd.turbo-stream.html, text/html, application/xhtml+xml',
    },
  })
    .then((response) => response.text())
    .then((html) => Turbo.renderStreamMessage(html));
}

function populateTurboFrame(url) {
  fetch(url, {
    headers: {
      Accept: 'text/vnd.turbo-stream.html',
    },
  })
    .then((response) => response.text())
    .then((html) => {
      Turbo.renderStreamMessage(html);
    });
}

function svgToBase64Img({ id, svg }) {
  const base64Svg = Base64.encode(svg);
  const img = document.createElement('img');
  img.src = `data:image/svg+xml;base64,${base64Svg}`;
  img.id = id;
  return img;
}

function featureFlagEnabled(flag) {
  if (!window?.Singulate?.feature_flags) {
    return false;
  }
  const decodedFlags = JSON.parse(Base64.decode(window.Singulate.feature_flags));

  return decodedFlags[flag] || false;
}

export { clearTurboFrame, populateTurboFrame, svgToBase64Img, featureFlagEnabled };
