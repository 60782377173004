<script>

  export let url = '';
  export let text = 'N/A';
  export let dataAttributes = {};
  export let target

</script>

<a href={url} class="text-primary-default hover:underline hover:text-primary-focus" target={target} {...dataAttributes}>
  {text}
</a>