import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['overviewToggleIcon', 'overview'];

  toggleOverviewList() {
    this.overviewTarget.classList.toggle('hidden');
    this.overviewToggleIconTarget.classList.toggle('rotate-180');
    this.updateOverviewCookie(this.overviewTarget.classList);
  }

  updateOverviewCookie(classList) {
    const overviewState = classList.contains('hidden') ? 'hidden' : 'visible';

    document.cookie = `campaign_editor_show_overview=${overviewState}; SameSite=Lax; path=/;`;
  }
}
