import * as Sentry from '@sentry/browser';
import { logger } from './logger';

if (!Sentry.getClient()) {
  try {
    Sentry.init({
      dsn: window.Singulate?.sentry_dsn ?? '',
      release: window.Singulate?.release_sha,
      environment: window.Singulate?.environment ?? 'development',
    });
    Sentry.setTag('exception_source', 'frontend');
    Sentry.setUser({ id: window.Singulate?.user_id });
  } catch (error) {
    logger.error(`Error configuring Sentry ${error}`);
  }
}

function errorHandler(error, message, detail) {
  if (window.Singulate?.environment !== 'production') {
    // eslint-disable-next-line no-console
    console.error(error);
  }
  logger.error({ error, message, detail });
  // logger.error(error);
  Sentry.captureException(error, { message, detail });
}

export { Sentry, errorHandler };
