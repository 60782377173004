import { Controller } from '@hotwired/stimulus';
// eslint-disable-next-line import/extensions
import Predictionary from 'predictionary/src/index.mjs';

export default class extends Controller {
  static values = { attributes: Array };

  static targets = ['attribute', 'input', 'suggestionsWrapper'];

  connect() {
    this.currentIndex = -1;
    this.currentValue = this.inputTarget.value;
    this.predictions = [];
    this.predictionary = Predictionary.instance();
    this.predictionary.addWords(this.attributesValue);
  }

  autoSuggest(event) {
    this.currentIndex = -1;
    this.predictions = [];
    this.currentValue = this.inputTarget.value;
    const { target } = event;

    const suggestions = this.predictionary.predict(target.value);
    this.attributeTargets
      .filter((attr) => suggestions.includes(attr.dataset.searchText))
      .forEach((attr, index) => {
        const prediction = this.predictionary.applyPrediction(this.currentValue, attr.dataset.searchText);
        attr.textContent = prediction;
        this.predictions[index] = prediction;
        attr.dataset.index = index;
        attr.classList.remove('hidden');
      });

    if (this.predictions.length > 0) {
      this.suggestionsWrapperTarget.classList.remove('hidden');
    } else {
      this.suggestionsWrapperTarget.classList.add('hidden');
    }

    this.attributeTargets
      .filter((attr) => !suggestions.includes(attr.dataset.searchText))
      .forEach((attr) => {
        attr.dataset.index = null;
        attr.classList.add('hidden');
      });
  }

  hideItems() {
    this.suggestionsWrapperTarget.classList.add('hidden');
    this.attributeTargets.forEach((attr) => {
      attr.classList.add('hidden');
    });
  }

  applyPrediction() {
    this.inputTarget.value = this.predictions[this.currentIndex];
    this.inputTarget.focus();
  }

  selectPrediction(event) {
    const { currentTarget } = event;
    this.currentIndex = currentTarget.dataset.index;
    this.inputTarget.value = this.predictions[this.currentIndex];
    this.hideItems();
  }

  arrowDown() {
    if (this.currentIndex === this.predictions.length - 1) {
      return;
    }
    this.currentIndex += 1;
    const attribute = this.attributeTargets.find((attr) => parseInt(attr.dataset.index, 10) === this.currentIndex);
    this.toggleFocus(attribute);
  }

  toggleFocus(attr) {
    this.attributeTargets.forEach((attribute) => attribute.classList.remove('bg-gray-100'));
    attr.classList.add('bg-gray-100');
    attr.focus();
  }

  arrowUp(event) {
    event.preventDefault();
    if (this.currentIndex <= 0) {
      return;
    }
    this.currentIndex -= 1;
    const attribute = this.attributeTargets.find((attr) => parseInt(attr.dataset.index, 10) === this.currentIndex);
    this.toggleFocus(attribute);
  }

  enter(event) {
    if (this.currentIndex >= 0) {
      event.preventDefault();
      this.inputTarget.value = this.predictions[this.currentIndex];
      this.hideItems();
      this.currentIndex = -1;
    }
  }

  outsideClick(event) {
    if (this.element === event.target || this.element.contains(event.target)) {
      return;
    }

    this.hideItems();
  }
}
