import { Controller } from '@hotwired/stimulus';
import { edit_smart_tag_templates_summary_instruction_path } from '@root/routes';
import { Turbo } from '@hotwired/turbo-rails';

export default class SummaryController extends Controller {
  static values = {
    id: String,
  };

  onClick() {
    const url = edit_smart_tag_templates_summary_instruction_path(this.idValue);
    Turbo.visit(url, { frame: 'summary_modal' });
  }
}
