import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static outlets = ['campaigns--editor--header-controls--preview-toggle'];

  static targets = ['contacts', 'contactToggle', 'contactToggleIcon'];

  static values = { randomContactIds: Array, selectedContactId: Number };

  toggleContacts() {
    this.contactsTarget.classList.toggle('hidden');
    this.contactToggleIconTarget.classList.toggle('rotate-180');
    this.contactToggleTargets.forEach((indicator) => {
      indicator.classList.toggle('hidden');
    });
    this.updateRandomContactsCookie(this.contactsTarget.classList);
  }

  updateRandomContactsCookie(classList) {
    const randomContactsState = classList.contains('hidden') ? 'hidden' : 'visible';

    document.cookie = `campaign_editor_show_random_contacts=${randomContactsState}; SameSite=Lax; path=/;`;
  }

  selectContact({ params: { contactId } }) {
    this.selectedContactIdValue = contactId;
  }

  randomContactIdsValueChanged() {
    if (!this.hasCampaignsEditorHeaderControlsPreviewToggleOutlet) return;

    this.campaignsEditorHeaderControlsPreviewToggleOutlet.setRandomContactIds(this.randomContactIdsValue);
  }

  selectedContactIdValueChanged() {
    if (!this.hasCampaignsEditorHeaderControlsPreviewToggleOutlet) return;

    this.campaignsEditorHeaderControlsPreviewToggleOutlet.setSelectedContactId(this.selectedContactIdValue);
  }
}
