import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['output'];

  changeSegmentationGroup(e) {
    const optionElement = e.target.selectedOptions[0];
    this.outputTarget.innerHTML = optionElement.dataset.html;
  }
}
