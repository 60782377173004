import BaseController from './base_controller';

export default class extends BaseController {
  static targets = ['currentTabId'];

  connect() {
    const currentTabId = window.Singulate.current_tab_id;
    this.currentTabIdTarget.value = currentTabId;
  }
}
