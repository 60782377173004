import BaseController from './base_controller';

// TODO: move this controller the component for editing campaign settings,
// requires that the campaigns 'new' page be updated to use the same component.
export default class extends BaseController {
  static targets = [
    'subject',
    'deliverUsing',
    'fromAddressContainer',
    'fromFriendlyContainer',
    'defaultFromAddressTemplate',
    'defaultFromFriendlyTemplate',
    'zapierFromAddressTemplate',
    'zapierFromFriendlyTemplate',
    'sendgridFromAddressTemplate',
    'sendgridFromFriendlyTemplate',
    'sendWithSingulateFromAddressTemplate',
    'sendWithSingulateFromFriendlyTemplate',
    'sendWithHubspotFromAddressTemplate',
    'sendWithHubspotFromFriendlyTemplate',
  ];

  static values = {
    deliverUsingTemplateMapping: Object,
  };

  connect() {
    this.updateFromOptions();
  }

  updateFromOptions() {
    const templateMapping =
      this.deliverUsingTemplateMappingValue[this.deliverUsingTarget.value] ||
      this.deliverUsingTemplateMappingValue.default;

    const fromAddressTarget = this[templateMapping.fromAddress];
    const fromFriendlyTarget = this[templateMapping.fromFriendly];

    this.fromAddressContainerTarget.innerHTML = fromAddressTarget.innerHTML;
    this.fromFriendlyContainerTarget.innerHTML = fromFriendlyTarget.innerHTML;
  }

  // Fires before the updateSubject event.
  prepareSubjectUpdate(e) {
    this.oldSubjectValue = e.target.value;
  }

  updateSubject(e) {
    if (this.subjectTarget.value === this.oldSubjectValue) {
      this.subjectTarget.value = e.target.value;
    }
  }
}
