import BaseController from './base_controller';

export function toast({ message, type, dismissAutomatically = true }) {
  const event = new CustomEvent('toast', {
    detail: {
      message,
      type,
      dismissAutomatically,
    },
  });
  window.dispatchEvent(event);
}
export default class extends BaseController {
  static values = {
    message: String,
    type: String,
    dismissAutomatically: Boolean,
  };

  static targets = ['messageContainer'];

  connect() {
    if (this.messageValue && this.typeValue) {
      this.showToast(this.messageValue, this.typeValue, this.dismissAutomaticallyValue);
    }
    // Listens for the window CustomEvent toast event
    // and displays the toast message.
    window.addEventListener('toast', (event) => {
      this.showToast(event.detail.message, event.detail.type, event.detail.dismissAutomatically);
    });
  }

  dismissToast() {
    this.element.classList.remove('show');
  }

  maybeHide() {
    if (!this.element.classList.contains('show')) {
      // removing "show" class only sets opacity to 0, but the toast is still there.
      // let's add "hidden" class, which sets display: none as well
      // so it cannot cover buttons or anything
      this.element.classList.add('hidden');
    }
  }

  showToast(message, type, dismissAutomatically) {
    this.messageContainerTarget.textContent = message;
    this.element.className = `toast toast-top toast-end alert ${type} z-[1000]`;
    this.element.classList.add('show');

    if (dismissAutomatically) {
      setTimeout(() => {
        this.dismissToast();
      }, 3000);
    }
  }
}
