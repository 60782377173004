import { Controller } from '@hotwired/stimulus';

// This component automatically keeps the latest version of the async element alive when in view.
// Polling automatically disables if the element is not in view.
// Connects to data-controller="turbo-modal"
export default class SuiAsyncController extends Controller {
  static values = {
    src: String,
    pollingInterval: Number,
    pollingEnabled: Boolean,
    shouldFollowRedirects: Boolean,
  };

  connect() {
    this.isStreamConnected = false;
    if (this.pollingEnabledValue) {
      this.interval = window.setInterval(this.autoReload.bind(this), this.pollingIntervalValue * 1000);
    }
    this.element.addEventListener('turbo:frame-missing', this.followRedirect.bind(this));
  }

  disconnect() {
    if (!this.pollingEnabledValue) {
      return;
    }
    window.clearInterval(this.interval);
  }

  autoReload() {
    if (this.isElementInViewport()) {
      this.reload();
    }
  }

  // Will follow a redirect in the event that the new page does not
  // contain the expected turbo-frame.
  followRedirect(event) {
    if (!this.shouldFollowRedirectsValue) {
      return;
    }

    if (event.detail.response.redirected) {
      event.preventDefault();
      event.detail.visit(event.detail.response);
    }
  }

  reload() {
    if (this.element.src !== this.srcValue) {
      this.element.src = this.srcValue;
    } else {
      this.element.reload();
    }
  }

  // Our logic goes here
  streamConnected() {
    if (!this.isStreamConnected) {
      this.isStreamConnected = true;
      // Once we connect or re-connect the stream - we reload the turbo-frame
      // To limit opportunities for race conditions.
      this.reload();
    }
  }

  streamDisconnected() {
    if (this.isStreamConnected) {
      this.isStreamConnected = false;
    }
  }

  isElementInViewport() {
    const rect = this.element.getBoundingClientRect();

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) /* or $(window).height() */ &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
    );
  }
}
