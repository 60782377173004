import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['previewEmailForm', 'selectedContactIdHiddenField', 'randomContactIdsHiddenField'];

  submitPreviewEmailForm() {
    this.previewEmailFormTarget.requestSubmit();
  }

  setRandomContactIds(contactIds) {
    this.randomContactIdsHiddenFieldTarget.value = contactIds.join(',');
  }

  setSelectedContactId(contactId) {
    this.selectedContactIdHiddenFieldTarget.value = contactId;
  }
}
