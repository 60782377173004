import SorterIcon from './sorter_icons.svelte';

/**
 * Replaces the default sort icon with a sui icon based on the direction of the sort
 */
export default function headerUISortCustomization(column, direction) {
  const div = document.createElement('div');
  new SorterIcon({ target: div, props: { direction } });
  return div;
}
