
<script>

  import PencilOneIcon from "@components/sui/icon/svgs/PencilOneIcon.svelte";

  export let icon = null;
  export let url = ''
  export let dataAttributes = {}
  export let color = 'default'

  // Variant color map
  const TEXT_COLOR_MAP = {
    default: 'text-base-content hover:text-base-content',
    primary: 'text-primary-default hover:text-primary-content',
    error: 'text-error-content hover:text-error-content',
    success: 'text-success-content hover:text-success-content',
    warning: 'text-warning-content hover:text-warning-content',
  };

</script>


<div class="w-full flex items-center justify-center">
  <a href={url} class={`w-8 h-8 rounded-lg flex items-center justify-center hover:bg-base-300 transition duration-75 ${TEXT_COLOR_MAP[color] ?? TEXT_COLOR_MAP.default} `} {...dataAttributes}>
    <div class="sui-icon sm">
        {#if icon}
          <svelte:component this={icon} />
        {:else}
          <!-- Default icon -->
          <PencilOneIcon/>
        {/if}
    </div>
  </a>
</div>