import { logger } from '@root/utilities/logger';
import { full_page_email_path } from '@root/routes';
import BaseController from './base_controller';
// Connects to data-controller="email-preview"
export default class extends BaseController {
  static targets = ['iframe'];

  static values = {
    emailId: Number,
  };

  toggleTags(showTags) {
    this.updateCookies(showTags);
    this.updateIframe(showTags);
  }

  togglePhoneMode(phoneMode) {
    this.iframeTarget.classList.toggle('w-[400px]', phoneMode);
    this.iframeTarget.classList.toggle('w-full', !phoneMode);
  }

  updateCookies(nextValue) {
    logger.debug(`Setting email_preview_show_previews_with_tags=${nextValue}`);
    document.cookie = `email_preview_show_previews_with_tags=${nextValue}; path=/;`;
  }

  updateIframe(nextValue) {
    this.iframeTarget.contentWindow.location.replace(full_page_email_path(this.emailIdValue, { with_tags: nextValue }));
  }

  iframeTargetConnected() {
    this.iframeTarget.onload = () => {
      this.iframeTarget.height = `${this.iframeTarget.contentWindow.document.body.scrollHeight + 500}px`;
    };
  }
}
