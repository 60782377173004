import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['label', 'select'];

  connect() {
    this.updateLabelText();
  }

  updateLabelText() {
    if (this.selectTarget.value === 'create') {
      this.labelTarget.textContent = 'create';
    } else if (this.selectTarget.value === 'skip') {
      this.labelTarget.textContent = 'skip';
    } else {
      this.labelTarget.textContent = 'map to';
    }
  }
}
