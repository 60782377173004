import { Turbo } from '@hotwired/turbo-rails';
import { edit_smart_tag_templates_liquid_instruction_path } from '@root/routes';
import { Controller } from '@hotwired/stimulus';

export default class LiquidController extends Controller {
  static values = {
    id: String,
  };

  onClick() {
    const url = edit_smart_tag_templates_liquid_instruction_path(this.idValue);
    Turbo.visit(url, { frame: 'modal' });
  }
}
