<script>
  import ChevronLeftIcon from '@components/sui/icon/svgs/ChevronLeftIcon.svelte'
  import ChevronLeftDoubleIcon from '@components/sui/icon/svgs/ChevronLeftDoubleIcon.svelte'
  import ChevronRightIcon from '@components/sui/icon/svgs/ChevronRightIcon.svelte'
  import ChevronRightDoubleIcon from '@components/sui/icon/svgs/ChevronRightDoubleIcon.svelte'

  export let onClick = () => {};
  export let disabled = false;
  export let active = false;
  export let icon = '';
  export let text = '';
</script>

<button
  on:click={onClick}
  disabled={disabled}
  class={`w-8 h-8 flex cursor-pointer items-center justify-center rounded-lg hover:bg-base-200 transition duration-75 ${active ? 'text-primary-default bg-info-focus hover:bg-info-focus' : ''} ${disabled ? 'opacity-10' : ''}`}
>
  <!-- If the icon was passed, render it based on the value -->
  {#if icon !== ''}
    <div class="sui-icon xs">
      {#if icon === 'chevron-left-double'}
        <ChevronLeftDoubleIcon />
      {:else if icon === 'chevron-right-double'}
        <ChevronRightDoubleIcon />
      {:else if icon === 'chevron-left'}
        <ChevronLeftIcon />
      {:else if icon === 'chevron-right'}
        <ChevronRightIcon />
      {/if}
    </div>
  {/if}

  <!-- Text -->
  {text}
  <slot></slot>
</button>