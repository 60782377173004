import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['lockedBy', 'locked', 'unlocked'];

  toggleLocked(lockedForMe) {
    this.unlockedTarget.classList.toggle('hidden', lockedForMe);
    this.lockedTarget.classList.toggle('hidden', !lockedForMe);
  }

  updateLockedBy(lockedByUserName) {
    this.lockedByTarget.innerText = `${lockedByUserName} is editing this campaign`;
  }
}
