import { Application } from '@hotwired/stimulus';
import { logger } from '@root/utilities/logger';
import { errorHandler } from '@root/utilities/error_tracking';
import * as Routes from '@root/routes';

const application = Application.start();

application.handleError = errorHandler;
logger.debug({ application }, 'Loading application controller v8.');
// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;
// Disables prefer default export
// import/prefer-default-export

// eslint-disable-next-line import/prefer-default-export
export { application };

window.Routes = Routes;
