import { Controller } from '@hotwired/stimulus';
import tinymce from 'tinymce/tinymce';
import 'tinymce/models/dom/model';
import 'tinymce/icons/default/icons';
import 'tinymce/themes/silver/theme';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/code';

import {
  defaultSetup,
  registerCustomIcons,
  customizeEditorToolbar,
  initializeContextToolbar,
  initializeAutoFormatting,
} from '@root/utilities/tinymce_utils';

export default class extends Controller {
  static targets = ['form', 'text_area'];

  connect() {
    this.setup();
  }

  setup() {
    if (tinymce.activeEditor) {
      tinymce.activeEditor.remove();
    }
    const self = this;

    tinymce.init({
      ...defaultSetup,
      toolbar: ['formatting image table horizontal-rule | code | kebab'],
      setup: (editor) => {
        // Custom setup start
        registerCustomIcons(editor);
        customizeEditorToolbar(editor, self, {
          liquid: false,
          segmentation: false,
          snippet: false,
          unsubscribe: false,
        });
        initializeContextToolbar(editor);
        initializeAutoFormatting(editor);
      },

      height: '600px',
      forced_root_block: 'p',
    });
  }
}
